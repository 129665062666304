.togglebutton {
  color: rgb(0, 0, 0) !important;
}

.loan-bank-scroll-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  background: white;
}

.loan-bank-scroll-container {
  display: flex;
  animation: loanBankScroll 20s linear infinite;
}

.loan-bank-scroll-item {
  flex: 0 0 auto;
  width: 100px;
}

@keyframes loanBankScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
