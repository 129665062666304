.emi-calculator-container {
  background-color: #eeeeee !important;
}
.emi-input {
  background-color: rgb(255, 255, 255) !important;
}

.nav-link.active {
  background-color: red !important;
  color: #ffffff !important;
}

.showbutton {
  color: rgb(255, 255, 255) !important;
  background-color: red !important;
  border: none;
  border-radius: 10px;
  padding: 10px 10px;
}
